import barba from "@barba/core";
import { animationShutter } from "./animation-shutter";

// head内のmetaタグ書き換え
const replaceHead = function (data) {
  const head = document.head;
  const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
  const newPageHead = document.createElement("head");
  newPageHead.innerHTML = newPageRawHead;

  const removeHeadTags = ["meta[name='keywords']", "meta[name='description']", "meta[property^='og']", "meta[name^='twitter']", "meta[itemprop]", "link[itemprop]", "link[rel='prev']", "link[rel='next']", "link[rel='canonical']"].join(",");

  const headTags = head.querySelectorAll(removeHeadTags);

  for (let i = 0; i < headTags.length; i++) {
    head.removeChild(headTags[i]);
  }

  const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);

  for (let i = 0; i < newHeadTags.length; i++) {
    head.appendChild(newHeadTags[i]);
  }
};

// 広告をクリア
const clearAllGAMSlots = () => {
  if (!window.googletag) {
    requestAnimationFrame(function () {
      googletag.cmd.push(function () {
        googletag.pubads().clear();
      });
    });
  }
};

// Google
const refreshGtag = () => {
  // Google Analytics
  if (typeof gtag === "function") {
    // inkrich と ユーザー独自のプロパティが設定される前提
    gtag("config", window.GA_INKRICH_ID, {
      page_path: window.location.pathname,
      dimension1: window.GA_SITE_TYPE,
      dimension2: window.GA_THEME,
    });
    // Google Analytics を設定しないユーザーもいるので設定していたら gtag を追加する
    if (window.GA_USER_ID) {
      gtag("config", window.GA_USER_ID, {
        page_path: window.location.pathname,
      });
    }
  }

  // Google AdSense
  // 参考:https://leap-in.com/ja/how-to-integrate-google-adsense-to-barba-jspjax/
  let gam_opt_divs = window.GAM_OPT_DIVS; // HTMLで定義されている適用ID
  requestAnimationFrame(function () {
    googletag.cmd.push(function () {
      googletag.pubads().refresh(); //広告の更新
      for (var i in gam_opt_divs) {
        googletag.display(gam_opt_divs[i]); //広告の再表示
      }
    });
  });
};

// サイト内遷移時にbody内の<script>を発火させる
const handleScriptInBody = async (container) => {
  const scripts = document.body.querySelectorAll("script");
  if (scripts.length === 0) return;
  const article = container.querySelector("[data-article]");
  if (!article) delete window.instgrm;

  // iframe内で外部scriptを展開し、展開後のHTMLを返す。
  // 参考: https://notes.sharesl.net/articles/1861/
  const getIframeHTML = async (script) => {
    return new Promise(async (resolve) => {
      const iframe = document.createElement("iframe");
      iframe.hidden = true;

      // iframeがbody上に存在しないと動作しないため一時的に出力する
      document.body.appendChild(iframe);

      // iframe内にscriptを書き込む
      const frameDocument = iframe.contentWindow.document;
      frameDocument.open();

      // head内にDOMがないと不具合を起こす事があるため、ダミーの要素を生成
      frameDocument.write("<script></script>");
      frameDocument.write(`<div id="area-to-write">${script.outerHTML}</div>`);
      frameDocument.close();

      // iframe内のDOMが変化したときの処理
      let timeoutId = 0;
      const observerCallback = (_, observer) => {
        let delay = 500;
        clearTimeout(timeoutId);

        // iframe内でscriptが展開された後、delayミリ秒の間、DOMに変化なければ、完全に展開したとみなし、HTMLを返し、監視を終了する。
        timeoutId = setTimeout(() => {
          resolve({
            head: frameDocument.head,
            body: frameDocument.getElementById("area-to-write"),
          });
          observer.disconnect();
        }, delay);
      };

      const observerOptions = {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      };

      // iframe内のDOMを監視する
      const observer = new MutationObserver(observerCallback);
      observer.observe(frameDocument, observerOptions);

      // サイト内遷移時にiframeを削除する
      barba.hooks.enter(() => iframe.remove());
    });
  };

  // scriptが読み込まれるのを待ちます。
  const scriptLoading = async (script) => {
    return new Promise((resolve) => {
      script.addEventListener("load", resolve);

      // 0.5秒経過しても読み込みが終わらなければ終了
      setTimeout(resolve, 500);
    });
  };

  // 外部スクリプトでdocument.writeなどを使用し、サイト内遷移で表示されないスクリプト
  window.expandIframeScripts = ["https://www.hotpepper.jp/doc/hpds/js/hpds_variable.js", "https://gist.github.com/"];

  /*
    あとから追加が必要になった場合はrawHTMLで先頭に以下を追記する
    <script>
      window.expandIframeScripts.unshift("展開できないscriptのurl");
    </script>
  */

  for (let i = 0; i < scripts.length; i++) {
    // 開発サーバーbrowser-syncをスキップする
    if (scripts[i].id === "__bs_script__") continue;
    if (scripts[i].src.indexOf("/browser-sync/browser-sync-client.js") > -1) continue;
    const parent = scripts[i].parentNode;

    // scripts[i] の src が expandIframeScript のいずれかにマッチする場合に true を返します。
    const isExpandIframeScript = window.expandIframeScripts.some((expandIframeScript) => {
      return scripts[i].src.includes(expandIframeScript);
    });

    // iframeの要素を元のスクリプトの位置に配置する
    const insertIframeNodes = (container) => {
      // insertBeforeでDOMが減少し、lengthが変わるため。
      const childNodesLength = container.childNodes.length;
      if (parent === null) return;

      parent.insertBefore(container, scripts[i]);

      for (let i = 0; i < childNodesLength; i++) {
        // insertBeforeでDOMが減少し、childNodesが変わるためchildNodes[0]。
        parent.insertBefore(container.childNodes[0], container);
      }

      container.remove();
    };

    // iframe上で展開してscriptのHTMLを取得する
    if (isExpandIframeScript) {
      const iframeHTML = await getIframeHTML(scripts[i]);
      insertIframeNodes(iframeHTML.head);
      insertIframeNodes(iframeHTML.body);
      scripts[i].remove();
      continue;
    }

    // 複製用のscript
    const script = document.createElement("script");

    // scriptの中身をコピーする
    script.innerHTML = scripts[i].innerHTML;

    // scriptのすべての属性をコピーする
    if (scripts[i].hasAttributes()) {
      const attrs = scripts[i].attributes;
      for (let i = 0; i < attrs.length; i++) {
        script.setAttribute(attrs[i].name, attrs[i].value);
      }
    }

    parent && parent.insertBefore(script, scripts[i]);
    scripts[i].remove();

    // jquery本体等、srcの読み込みを待つ必要があるため、読み込みを同期的に行う
    if (scripts[i].src && !scripts[i].async && !scripts[i].defer) {
      await scriptLoading(script);
    }
  }
};

// 同じurlの場合、ページ遷移をさせない
const noTransitionToCurrentPage = () => {
  const eventDelete = (e) => {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
      window.scrollTo(0, 0);
      return;
    }
  };

  const links = [...document.querySelectorAll("a[href]")];
  links.forEach((link) => {
    link.addEventListener(
      "click",
      (e) => {
        eventDelete(e);
      },
      false
    );
  });
};

// リンクをクリックまたはタップした時のスクロール処理
const whenClickedLink = () => {
  if (location.hash) {
    // #の位置で表示
    const el = document.getElementById(location.hash.split("#")[1]);
    document.documentElement.scrollTop = el !== null ? el.offsetTop : 0;
  } else {
    // #がなければスクロールトップへ
    window.scrollTo(0, 0);
  }
};

// ローカルストレージを使用したスクロール位置の制御
let currentPathname = location.pathname; // 各ページのスクロール情報をURLと紐付けするため
const SCROLL_POSITIONS_KEY = "scrollPositions"; // ローカルストレージに使用する名前

// ローカルストレージからスクロール位置を取得する
const getScrollPosition = () => {
  const getLocalStorage = localStorage.getItem(SCROLL_POSITIONS_KEY);
  return getLocalStorage !== null ? JSON.parse(getLocalStorage) : [];
};

// ローカルストレージにスクロール位置を保存する
const setScrollPosition = () => {
  localStorage.setItem(
    SCROLL_POSITIONS_KEY,
    JSON.stringify([
      ...getScrollPosition().filter((scrollPosition) => scrollPosition.pathname !== currentPathname),
      {
        pathname: currentPathname,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        bodyHeight: document.body.clientHeight,
      },
    ])
  );
};

// ブラウザバックまたはブラウザフォワードした時のスクロール処理
let scrollRestorationIntervalID = 0; // setIntervalを記録する
let scrollRestorationTimeoutID = 0; // setTimeoutを記録する
const whenPopstate = () => {
  // 前のページ遷移のintervalの終了（ある場合）
  clearInterval(scrollRestorationIntervalID);
  // 前のページ遷移のtimeoutの終了（ある場合）
  clearTimeout(scrollRestorationTimeoutID);

  // 現在のページのscroll位置を取得する
  const scrollPosition = getScrollPosition().find((scrollPosition) => {
    return scrollPosition.pathname === location.pathname;
  });

  // 前のbodyと今のbodyの高さが同じかどうかをチェックする
  const isSameBodyHeight = (bodyHeight) => {
    return bodyHeight === document.body.clientHeight;
  };

  if (scrollPosition && isSameBodyHeight(scrollPosition.bodyHeight)) {
    // scroll位置を復元
    window.scrollTo(scrollPosition.scrollX, scrollPosition.scrollY);
  } else {
    window.scrollTo(0, 0);
  }

  // 前のbodyの高さが今のbodyの高さと同じになるまで繰り返す
  const timeInterval = 100; // スクロール位置の復元が可能かどうかを確認するインターバル 1秒 = 1000
  scrollRestorationIntervalID = setInterval(() => {
    if (scrollPosition && isSameBodyHeight(scrollPosition.bodyHeight)) {
      // scroll位置を復元
      window.scrollTo(scrollPosition.scrollX, scrollPosition.scrollY);
      // 繰り返しを終了
      clearInterval(scrollRestorationIntervalID);
    }
  }, timeInterval);

  // timeLimit秒経過してもスクロール位置の復元ができなければ終了
  const timeLimit = 10000; // スクロール位置の復元を待つ時間 1秒 = 1000
  scrollRestorationTimeoutID = setTimeout(() => {
    clearInterval(scrollRestorationIntervalID);
  }, timeLimit);

  // ユーザーがスクロール or スワイプしたら終了
  const whenUserScroll = () => {
    clearInterval(scrollRestorationIntervalID);
    document.removeEventListener("wheel", whenUserScroll);
    document.removeEventListener("touchmove", whenUserScroll);
  };

  document.addEventListener("wheel", whenUserScroll, { passive: false });
  document.addEventListener("touchmove", whenUserScroll, { passive: false });
};

// スクロール位置の処理
const handleScrollPosition = (popstate) => {
  if (popstate) {
    // ブラウザバック or ブラウザフォワード時は保存したスクロールの位置を復元する
    whenPopstate();
  } else {
    // リンクをタップして画面遷移した場合は、トップまたは「#」の位置までスクロールする
    whenClickedLink();
  }

  // スクロール位置を保存する際に使用
  currentPathname = location.pathname;
};

// ブラウザバック、ブラウザフォワードを検知する
const handlePopstate = (trigger) => trigger === "back" || trigger === "forward" || trigger === "popstate";

// URLを直接入力して遷移した場合でも、ページジャンプを制御する
export const handleHashChanged = () => {
  if (location.hash === "") return;
  const targetHash = location.hash;
  location.hash = "";
  const target = document.getElementById(targetHash.slice(1));
  const headerMenu = document.getElementsByClassName("l-header__menu")[0];
  const headerStickyClassName = "js-header__menu--sticky";
  let headerMenuHeight = 0;

  // sticky状態で高さを取得する
  if (headerMenu.classList.contains(headerStickyClassName)) {
    headerMenuHeight = headerMenu.clientHeight;
  } else {
    headerMenu.classList.add(headerStickyClassName);
    headerMenuHeight = headerMenu.clientHeight;
    headerMenu.classList.remove(headerStickyClassName);
  }

  setTimeout(() => {
    history.replaceState({}, "", targetHash);
    window.scrollTo(0, target ? target.offsetTop - headerMenuHeight : 0);
  }, 250);
};

// barba.jsの処理
export const initBarba = (handleLoadPage, handleAnimation) => {
  barba.init({
    // DBに負荷をかけているため、リンクホバーのプリフェッチをオフにする
    prefetchIgnore: true,
    // ページ遷移が完了するまで別ページへの遷移をキャンセル
    preventRunning: true,

    // エラーを起こす内部リンクにクラスをつける
    requestError: (trigger, action, url, response) => {
      if (action === "enter" && !trigger.classList.contains("js-prevent-barba")) {
        trigger.classList.add("js-prevent-barba");
      }
    },

    // 上記のクラスがついたリンクはbarba遷移しない
    prevent: ({ el }) => el.classList && el.classList.contains("js-prevent-barba"),

    // ページ遷移フック
    transitions: [
      {
        name: "default-transition",
        once(data) {
          noTransitionToCurrentPage();

          handleHashChanged();

          // スクロール位置の復元を手動で行う
          if (history.scrollRestoration) history.scrollRestoration = "manual";

          // リンクから遷移してきた場合
          if (window.performance.navigation.type === 0) {
            if (location.hash !== "") {
              const hash = location.hash;
              location.hash = "";
              location.hash = hash;
            }
          } else {
            // リロードやブラウザバック等でスクロール位置の復元
            whenPopstate();
          }

          // 外部サイトに遷移するときにもスクロール位置を格納する
          window.addEventListener("beforeunload", setScrollPosition);
          // ios向けの記述
          window.addEventListener("pagehide", setScrollPosition);
        },
        before() {
          clearAllGAMSlots();
          // ページ遷移前にスクロール位置を保存する
          setScrollPosition();
        },
        async enter(data) {
          data.next.container.style.opacity = 0;

          // data-barba-namespaceの値が同じなら遷移アニメーションをしない
          if (data.current.namespace !== data.next.namespace && !handlePopstate(data.trigger)) {
            await animationShutter.in();
          }

          // 遷移後
          const container = data.next.container;
          replaceHead(data);
          handleLoadPage(container);
        },
        after(data) {
          data.next.container.style.opacity = "";

          // data-barba-namespaceの値が同じなら遷移アニメーションをしない
          const hasAnimation = () => {
            if (data.current.namespace !== data.next.namespace && !handlePopstate(data.trigger)) {
              return animationShutter.out;
            }
            return false;
          };

          const container = data.next.container;
          handleAnimation(hasAnimation(), container);
          handleScriptInBody(container);

          // 広告の再表示
          refreshGtag();

          // スクロール位置の処理
          handleScrollPosition(handlePopstate(data.trigger));
        },
      },
    ],
  });
};
