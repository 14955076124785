import barba from "@barba/core";

// aタグの中の対象の要素に data-href を設定する
export const linkInAnchor = {
  handleClick: function (e) {
    if (!e.target.hasAttribute("data-href")) return;
    e.stopPropagation();
    e.preventDefault();
    // TODO: barba.jsを除去する際に変更する
    barba.go(e.target.getAttribute("data-href"));
    // window.location.href = e.target.getAttribute("data-href");
  },
  init: function (anchors) {
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", this.handleClick);
    });
  },
};
